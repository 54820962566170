import React, { useState, useEffect } from 'react';
import '../css/App.css';


import AccordionTest from './AccordionComponents/AccordionTest';

import { SimpleGrid, Box, useToast, Flex, Text, SliderThumb, HStack, Button, Icon, IconButton, Tooltip } from '@chakra-ui/react'
import { FiRotateCw } from "react-icons/fi";

import MapScreen from '../GlobalComponents/Map/MapScreen';
import WebsiteHeader from '../GlobalComponents/WebsiteHeader';



export default function ViewManifest(props) {
    const [LieferDetail, setLieferDetail] = useState(false);
    //const [LieferDetail, setLieferDetail] = useState({ Zustell_Zeit: 'thisDayAndAge', lat: 50.8203893000, long: 13.0812356000 });

    const [ShowCustomer, setShowCustomer] = useState(false);
    const [ShowAblade, setShowAblade] = useState(true);

    const [AbladeSt, setAbladeSt] = useState(0);
    const [LieferSt, setLieferSt] = useState(0);

    useEffect(() => {
        
        if(props.ActiveUser.User_Prefers !== null && props.ActiveUser.User_Prefers !== undefined && props.ActiveUser.User_Prefers.ShowLieferFirst) {
            setShowCustomer(true);
        }
   
    },[props.ActiveUser]);

    useEffect(() => {

        props.viewCall('reload')

    },[])

    function AccordionCall(command, value1, value2) {
        if (command === 'createPDF') {
            props.viewCall('createPDF', value1, value2);
        }
        if (command === 'setLiefer') {
            setLieferDetail(value1)
        }
    }
    async function MapCall(comand, value1, value2) {
        if (comand === 'setMap') {
            props.viewCall('setMap', value1)
        }
    }
    function makeBeladePDF(Manifest) { props.viewCall('createBeladePDF', Manifest); }
    function makePaketPDF(Manifest) { props.viewCall('createPaketPDF', Manifest); }
    function CustomerVisibility() {

        var Show = ShowCustomer
        setShowCustomer(!Show)
        props.viewCall('setFirst', !Show)
    }
    function AbladeVisibility() {

        var Show = ShowAblade
        setShowAblade(!Show)
    }
    /*
    async function _fetchData(ManifestNr) { //unused
        try {
            const response = await fetch(
                'https://' + props.server + '/API/index.php/GET_Tour?Tour='
                + ManifestNr);
            const data = await response.json();
            ////console.log(data.Tour)
            setTourData(data.Tour);
            setAbladeSt(0);
            setLieferSt(0);
        } catch (error) {
            //console.log(error)
            alert('Verbindung Fehlgeschlagen!');
        }
        ////console.log(TourData)
    }
    */
    return (
        <Flex flexDir='column'>
            <WebsiteHeader Text='TourPortal / Manifest Ansicht' onClick={() => props.viewCall('back')} server={props.server} User={props.ActiveUser} />
            <Flex height='93vh' flexShrink={1} backgroundColor='#ccc' p={1} flexDirection='row'>
                <Flex flexDirection='column' overflow='auto' width='45%'>
                    <Box flex={1} borderWidth={1} borderRadius={3} borderColor='#000' mb={1} bg='#fff' padding={2} overflow='auto'>
                    <Flex mb={0} justifyContent='space-between'>
                        
                        <Text fontSize={25} fontWeight={'bold'}>Manifest: {props.TourData[0]?.Manifest}</Text>
                        
                        <Tooltip label="Seite neu laden" placement='top' hasArrow bg='#666'>
                            <IconButton bg='#1DA1F2' color='#fff' width='55px' height='40px'
                                onClick={() => props.viewCall('reload')}
                                icon={<Icon as={ FiRotateCw } color='#fff' boxSize={35}/>}/>
                        </Tooltip>
                    </Flex>

                        <Flex m={2} mb={0} flexWrap='wrap'>
                            { props.curentManifest?.Tour ? (
                            <>
                            <Flex>
                                <Flex flexDirection='column' marginRight={5}>
                                    <Text style={DataGridHead}>Fahrer:</Text>
                                    <Text style={DataGridHead}>Tour:</Text>
                                    <Text style={DataGridHead}>Abladestellen:</Text>
                                    <Text style={DataGridHead}>Lieferstellen:</Text>
                                    <Text style={DataGridHead}>Pakete:</Text>
                                    <Text style={DataGridHead}>Papier:</Text>                 
                                </Flex>
                                <Flex flexDirection='column' marginRight={10}>
                                    <Text style={DataGrid}>{props.curentManifest.Fahrer_Name}</Text>
                                    <Text style={DataGrid}>{props.curentManifest.Tour}</Text>
                                    <Text style={DataGrid}>{props.curentManifest.Ablade_Anzahl}</Text>
                                    <Text style={DataGrid}>{props.curentManifest.Liefer_Anzahl}</Text>
                                    <Text style={DataGrid}>{props.curentManifest.Pakete_Anzahl}</Text>
                                    <Text style={DataGrid}>{props.curentManifest.Papier_Anzahl}</Text>
                                </Flex>
                            </Flex>
                            <Flex>
                                <Flex flexDirection='column' marginRight={5}>
                                    <Text style={DataGridHead}>Datum:</Text>
                                    <Text style={DataGridHead}>Status:</Text>
                                    <Text style={DataGridHead}>Gestartet:</Text>
                                    <Text style={DataGridHead}>Beendet:</Text>
                                </Flex>
                                <Flex flexDirection='column'>
                                    <Text style={DataGrid}>{props.curentManifest.Datum}</Text>
                                    <Text style={DataGrid}>{props.curentManifest.Status}</Text>
                                    <Text style={DataGrid}>{props.curentManifest.Start_Zeit}</Text>
                                    <Text style={DataGrid}>{props.curentManifest.End_Zeit}</Text>
                                </Flex>
                            </Flex>
                            </>
                            ):(null)}
                        </Flex>
                    </Box>
                    <Box flex={2.5} borderWidth={1} borderRadius={3} borderColor='#000' bg='#fff'>
                        <MapScreen TourData={props.TourData} RouteObj={props.RouteObj} server={props.server}
                            singleLieferSt={LieferDetail} editMap={false} AllowComputeRoute AllowRealRoute ShowDriver 
                            ShowDriverData={props.DriverData} ActiveUser={props.ActiveUser} MapCall={MapCall}/>
                    </Box>
                </Flex>
                <Flex flex={1} flexDirection='column' width='55%'>
                    <Box flex={1} borderWidth={1} borderRadius={3} borderColor='#000' ml={1} bg='#fff' padding={2} overflow='auto'>
                        <Flex flexWrap='wrap' justifyContent='space-between' alignItems='center' my={2}>
                            <HStack spacing={0.5} mx={2} mb={1}>
                                <Button colorScheme='twitter' borderRadius={'5px 0px 0px 5px'} variant='outline' onClick={() => CustomerVisibility()} 
                                isDisabled={ShowAblade === false}>
                                    {ShowCustomer ? ('Verstecke Kunden'):('Zeige Kunden')}
                                </Button>
                                <Button colorScheme='twitter' borderRadius={'0px 5px 5px 0px'} variant='outline' onClick={() => AbladeVisibility()}>
                                    {ShowAblade ? ('Verstecke Abladestellen'):('Zeige Abladestellen')}
                                </Button>
                            </HStack>
                            <HStack spacing={0.5} mx={2} mb={1}>
                                <Tooltip label="Das Manifest als PDF ausgeben" placement='top' hasArrow bg='#666'>
                                    <Button colorScheme='green' borderRadius={'5px 0px 0px 5px'} onClick={() => makeBeladePDF(props.TourData[0]?.Manifest

                                    )}  >Papier-Manifest Erstellen</Button>
                                </Tooltip>
                                <Tooltip label="Die Beladeliste als PDF ausgeben" placement='top-start' hasArrow bg='#666'>
                                    <Button colorScheme='green' borderRadius={'0px 5px 5px 0px'} onClick={() => makePaketPDF(props.TourData[0]?.Manifest
                                    )} 
                                    >Beladeliste Erstellen</Button>
                                </Tooltip>
                            </HStack>
                        </Flex>
                        <Box flex={1} flexWrap='wrap'
                        my={2} overflow='auto'>
                            <AccordionTest TourData={props.TourData} 
                            LieferData={props.TourData.length !== 0 && props.TourData[AbladeSt].Lieferstellen.length !== 0 ? props.TourData[AbladeSt].Lieferstellen[LieferSt].Pakete : []} ShowCustomer={ShowCustomer} ShowAblade={ShowAblade} ManifestNr={
                                props.TourData.length !== 0 ? props.TourData[0].Manifest : ''
                                } AccordionCall={AccordionCall} server={props.server} 
                                route={props.route + (props.TourData.length !== 0 ? "?manifest=" + props.TourData[0].Manifest : "") } />
                        </Box>
                    </Box>
                </Flex>
            </Flex>
        </Flex >
    );
}

const DataGridHead = {
    fontSize: 18, 
    marginTop: -2, 
    fontWeight: 'bold',
    height: '25px'
}
const DataGrid = {
    fontSize: 18, 
    marginTop: -2, 
    height: '25px'
    
}

